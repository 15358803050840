<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    routeback="/fornecedor/cadastro/tag-produto"
  >
    <FormField label="Tag" v-model="form.valor" :validation="$v.form.valor" />
    <FormField
      label="Nome interno"
      v-model="form.descricao"
      :validation="$v.form.descricao"
    />

    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormField from '@/components/form/FormField'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import {
  TAGPRODUTO_LOAD,
  TAGPRODUTO_CREATE,
  TAGPRODUTO_UPDATE
} from '@/store/actions/tagsProduto'
import FormButton from '@/components/form/FormButton'

export default {
  name: 'TagPesquisaForm',
  components: {
    SidePopup,
    FormField,
    FormButton
  },
  validations: {
    form: {
      descricao: { required },
      valor: { required }
    }
  },
  data: () => ({
    mode: 'add',
    form: {
      descricao: '',
      valor: ''
    }
  }),
  computed: {
    ...mapGetters(['getFornecedorId']),
    title() {
      return this.mode == 'edit'
        ? 'Editar tag de produto'
        : 'Nova tag de produto'
    }
  },
  methods: {
    onClose(routeback = false) {
      this.$emit('close')
      if (routeback) {
        this.$router.replace('/fornecedor/cadastro/tag-produto')
      }
    },
    getData(id) {
      this.$store.dispatch(TAGPRODUTO_LOAD, id).then(resp => {
        this.form = {
          ...this.form,
          ...resp
        }
      })
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (this.mode == 'add') {
        this.$store.dispatch(TAGPRODUTO_CREATE, this.form).then(() => {
          this.$vueOnToast.pop(
            'success',
            'Tag de pesquisa cadastrada com sucesso'
          )
          this.onClose(true)
        })
      } else {
        this.$store.dispatch(TAGPRODUTO_UPDATE, this.form).then(() => {
          this.$vueOnToast.pop(
            'success',
            'Tag de pesquisa atualizada com sucesso'
          )
          this.onClose(true)
        })
      }
    }
  },
  created() {
    const idRoute = this.$route.params.id
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    }
  }
}
</script>
